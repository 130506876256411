class WeiboController {
  authorize() {
    return new Promise(function (resolve, reject) {
      const listener = function (e) {
        if (e.data.event) {
          if (e.data.event === "oauth.done") {
            resolve({
              accessToken: e.data.token,
              userId: e.data.userId,
              email: e.data.email,
              provider: "weibo",
            });
          } else if (e.data.event === "oauth.failed") {
            reject(new Error("weibo_auth_failed"));
          }
        }
      };
      window.addEventListener("message", listener);
      window.open(
        "https://api.weibo.com/oauth2/authorize?client_id=1391921586&response_type=code&scope=all&redirect_uri=" +
          encodeURIComponent("https://amzsc.amzgb.com/oauth2-done.html")
      );
    });
  }
}

export default WeiboController;
